import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, image, url, themeKey }) => {
  const { pathname, origin } = useLocation();
  // eslint-disable-next-line no-use-before-define
  const { site } = useStaticQuery(query);
  const { defaultTitle, defaultDescription, siteUrl, defaultImage } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}/${defaultImage}`,
    url: url || `${siteUrl}${pathname}`,
    themeKey,
    origin,
  };

  return (
    <Helmet title={seo.title} htmlAttributes={{ lang: 'no' }} defer={false}>
      <meta property="og:type" content="website" />
      {seo.image && <meta name="image" content={seo.image} />}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.url && <meta property="twitter:url" content={seo.url} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.origin && <meta property="twitter:domain" content={seo.origin} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {/* Favicons should be placed in static/assets/favicon/{themeKey} */}
      {/* Generate a package here: https://realfavicongenerator.net/ */}
      {/* Helmet doesn't support fragments, hence the repeat checks */}
      {seo.themeKey && (
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/assets/favicons/${seo.themeKey}/apple-touch-icon.png`}
        />
      )}
      {seo.themeKey && (
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/assets/favicons/${seo.themeKey}/favicon-32x32.png`}
        />
      )}
      {seo.themeKey && (
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/assets/favicons/${seo.themeKey}/favicon-16x16.png`}
        />
      )}
      {/* {seo.themeKey && (
        <link rel="manifest" href={`/assets/favicons/${seo.themeKey}/site.webmanifest`} />
      )} */}
      {seo.themeKey && (
        <link
          rel="mask-icon"
          href={`/assets/favicons/${seo.themeKey}/safari-pinned-tab.svg`}
          color="#5bbad5"
        />
      )}
      {seo.themeKey && (
        <link rel="shortcut icon" href={`/assets/favicons/${seo.themeKey}/favicon.ico`} />
      )}
      {seo.themeKey && (
        <meta
          name="msapplication-config"
          content={`/assets/favicons/${seo.themeKey}/browserconfig.xml`}
        />
      )}
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};
export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  themeKey: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  url: '',
  themeKey: '',
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
