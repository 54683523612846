import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';

initializeApp({
  apiKey: 'AIzaSyDIAnaDNk3y7dA9KCpVb9_YZz7wzI1dgFs',
  authDomain: 'enchant-mini.firebaseapp.com',
  projectId: 'enchant-mini',
  storageBucket: 'enchant-mini.appspot.com',
  messagingSenderId: '793982325085',
  appId: '1:793982325085:web:964e1b6d46bd16ab9118b4',
});

const db = getFirestore();

// export async function readFire() {
//   const querySnapshot = await getDocs(collection(db, 'testcollection'));
//   const output = querySnapshot.docs.map(doc => {
//     return `${doc.id} => ${doc.data()}`;
//   });
//   return output;
// }

export async function createFire(collectionId, initials) {
  /* addDoc will create a new doc with an arbitrary ID which we can
  pick up from the return value and use for later. */
  console.log(collectionId);
  try {
    const docRef = await addDoc(collection(db, collectionId), initials);
    const msg = `Document written with ID: ${docRef.id}`;
    // console.log('msg: ', msg);
    return docRef.id;
  } catch (err) {
    console.error('Error adding document: ', err);
    return err;
  }
}

// deleteFire - where dev mode has you deleting the node.

export async function updateFire({ node, id }, data) {
  // We could have a switch where the update only happens if not cheat is enabled
  try {
    const quizRef = doc(db, node, id);
    setDoc(quizRef, data, { merge: true });
  } catch (err) {
    console.log('There is an error');
    console.error('Error', err);
  }
}
