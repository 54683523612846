/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { StateInspector } from 'reinspect';
import { StateProvider, configuredReducer, configuredInitState } from './src/cpt/EnchantQuiz/state';
import SEO from './src/cpt/SEO';

export const wrapRootElement = ({ element }) => {
  // REVIEW: Do we still need SEO cpt injected here?
  return (
    <>
      <SEO />
      <StateInspector name="enchant">
        <StateProvider reducer={configuredReducer} initState={configuredInitState}>
          {element}
        </StateProvider>
      </StateInspector>
    </>
  );
};
